import { useFormik } from "formik"
import * as Yup from "yup"
import TextField from "components/TextField"
import { useMemo, useState } from "react"
import Button from "components/Button"
import RadioButton from "components/RadioButton"
import commonFunctions from "utils/CommonFunctions"
import Table from "../../../../components/Table/Table"
import AddTeammate from "../AddTeammate"
import { skillLevels } from "Pages/Events/constants"

const Tennis = ({ registerEvent, event = null }) => {
  const [openAddPlayer, setOpenAddPlayer] = useState(false)
  const [category, setCategory] = useState(null)
  const [categoryError, setCategoryError] = useState(false)
  const [registerThrough, setRegisterThrough] = useState(null)
  const [costType, setCostType] = useState("default")
  const [players, setPLayers] = useState([])

  
  const eventType = useMemo(() => event?.eventType?.value, [event])

  const doublePlayers = [
    'adult-doubles',
    'youth-doubles'
  ]

  const columns = [
    {
      name: "Firstname",
      selector: "firstName",
      cell: (row) => <span>{row.firstName}</span>,
    },
    {
      name: "Lastname",
      selector: "lastName",
    },
    {
      name: "Contact number",
      selector: "contactNumber",
    },
    {
      name: "Skill Level",
      selector: "skillLevel",
      cell: row => <span>{getSkillLevel(row.skillLevel).toString()}</span>
    },
    {
      name: "Actions",
      selector: "action",
      style: { width: "40px" },
      cell: (row, index) => (
        <div className="flex items-center space-x-2">
          <button
            title="Mark As Unread"
            onClick={(e) => {
              e.preventDefault()
              handleRemovePLayer(index)
            }}
            className="text-gray-700 px-2 py-1 border border-gray-300 rounded-lg shadow hover:bg-gray-200 transition duration-100"
          >
            Remove
          </button>
        </div>
      ),
    },
  ]

  const getSkillLevel = (level) => {
    return `${skillLevels.filter(s => s.value === level)[0].name} - ${level}`
  }

  const [allFieldsValidated, setAllFieldsValidated] = useState(false)

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      primaryContactName: "",
      email: "",
      confirmEmail: "",
      phone: "",
      alternatePhone: "",
    },
    validationSchema: Yup.object().shape({
      primaryContactName: Yup.string().required("Required"),
      email: Yup.string().required("Required"),
      confirmEmail: Yup.string()
        .required("Required")
        .oneOf([Yup.ref("email"), null], "Email must match"),
      phone: Yup.string().required("Phone number is required"),
    }),
    onSubmit: (values) => {
      if (!allFieldsValidated) return

      values["players"] = players
      values["registerThrough"] = registerThrough
      values["teamType"] = category
      values["eventCost"] = event.eventCost[costType]
      values["phone"] = commonFunctions.phoneNumberToText(values["phone"])
      values["alternatePhone"] = commonFunctions.phoneNumberToText(values["alternatePhone"])
      registerEvent(values)
    },
  })

  const validateOtherFields = () => {
    let hasIssues = false

    // Validate category
    if (category === null) {
      setCategoryError(true)
      hasIssues = true
    }

    setAllFieldsValidated(!hasIssues)

    return hasIssues
  }

  const handleAddPlayer = (player) => {
    setPLayers([...players, player])
  }

  const handleRemovePLayer = (index) => {
    let newPlayers = [...players]
    newPlayers.splice(index, 1)
    setPLayers(newPlayers)
  }

  return (
    <section>
      <AddTeammate open={openAddPlayer} setOpen={setOpenAddPlayer} handleAddPlayer={handleAddPlayer} eventType={eventType} category={category} />

      <form className="space-y-4 md:space-y-6">
        <div className="grid grid-cols-1 gap-6 mt-4 sm:grid-cols-2">
          <div>
            <TextField
              label="Name of primary contact"
              name="primaryContactName"
              type="text"
              value={formik.values.primaryContactName}
              onChange={formik.handleChange}
              error={formik.errors.primaryContactName}
              touched={formik.touched.primaryContactName}
              onBlur={formik.handleBlur}
              required
            />
          </div>
        </div>
        <div className="grid grid-cols-1 gap-6 mt-4 sm:grid-cols-2">
          <div>
            <TextField
              label="Email"
              name="email"
              type="text"
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.errors.email}
              touched={formik.touched.email}
              onBlur={formik.handleBlur}
              required
            />
          </div>
          <div>
            <TextField
              label="Confirm Email"
              name="confirmEmail"
              type="text"
              value={formik.values.confirmEmail}
              onChange={formik.handleChange}
              error={formik.errors.confirmEmail}
              touched={formik.touched.confirmEmail}
              onBlur={formik.handleBlur}
              required
            />
          </div>

          <div>
            <TextField
              label="Phone number"
              name="phone"
              type="text"
              placeholder="(XXX) XXX-XXXX"
              phoneNumberFormat="true"
              value={formik.values.phone}
              onChange={formik.handleChange}
              error={formik.errors.phone}
              touched={formik.touched.phone}
              onBlur={formik.handleBlur}
              required
            />
          </div>
          <div>
            <TextField
              label="Alternate phone number"
              name="alternatePhone"
              type="text"
              placeholder="(XXX) XXX-XXXX"
              phoneNumberFormat="true"
              value={formik.values.alternatePhone}
              onChange={formik.handleChange}
              error={formik.errors.alternatePhone}
              touched={formik.touched.alternatePhone}
              onBlur={formik.handleBlur}
            />
          </div>
        </div>

        <div>
          <label htmlFor={"category"} className="block text-sm font-medium text-gray-700 mb-3">
            Participant Category
            <span className="text-md text-red-500">*</span>
          </label>
          <div className="flex items-center mb-2">
            <RadioButton
              name="adult-singles"
              value="adult-singles"
              onChange={(e) => {
                setCategory(e.target.value)
                setCostType(e.target.value)
                setCategoryError(false)
              }}
              error={categoryError}
            >
              Adult Singles {<GetCost costs={event.eventCost} name="adult-singles" />}
            </RadioButton>
          </div>
          <div className="flex items-center mb-2">
            <RadioButton
              name="adult-doubles"
              value="adult-doubles"
              onChange={(e) => {
                setCategory(e.target.value)
                setCostType(e.target.value)
                setCategoryError(false)
              }}
              error={categoryError}
            >
              Adult Doubles {<GetCost costs={event.eventCost} name="adult-doubles" />}
            </RadioButton>
          </div>
          <div className="flex items-center mb-2">
            <RadioButton
              name="youth-singles"
              onChange={(e) => {
                setCategory(e.target.value)
                setCostType(e.target.value)
                setCategoryError(false)
              }}
              value="youth-singles"
              error={categoryError}
            >
              Youth Singles {<GetCost costs={event.eventCost} name="youth-singles" />}
            </RadioButton>
          </div>
          <div className="flex items-center mb-2">
            <RadioButton
              name="youth-doubles"
              value="youth-doubles"
              onChange={(e) => {
                setCategory(e.target.value)
                setCostType(e.target.value)
                setCategoryError(false)
              }}
              error={categoryError}
            >
              Youth Doubles {<GetCost costs={event.eventCost} name="youth-doubles" />}
            </RadioButton>
          </div>
          <div className="flex items-center mb-2">
            <RadioButton
              name="kids-singles"
              value="kids-singles"
              onChange={(e) => {
                setCategory(e.target.value)
                setCostType(e.target.value)
                setCategoryError(false)
              }}
              error={categoryError}
            >
              Kids Singles (12 and under)
              {<GetCost costs={event.eventCost} name="kids-singles" />}
            </RadioButton>
          </div>
          {categoryError && <span className="text-red-600 text-xs">Required</span>}
        </div>

        {doublePlayers.includes(category) && (<div>
          <label htmlFor={"category"} className="block text-sm font-medium text-gray-700 mb-3">
            Team Info
          </label>
          <Button
            onClick={() => {
              if (players.length >= event?.maxPlayers) {
                alert("You can only add up to " + event?.maxPlayers + " players. ")
              } else {
                setOpenAddPlayer(true)
              }
            }}
          >
            Add Player
          </Button>
          <div className="text-sm">You can add players up to {event?.maxPlayers}</div>

          <Table columns={columns} data={players} loading={false} disableFooter={true} />
        </div>)}
      </form>

      <div className="bg-gray-50 py-3 sm:flex sm:flex-row-reverse">
        <Button
          onClick={() => {
            setRegisterThrough("OFFLINE")
            validateOtherFields()
            formik.handleSubmit()
          }}
          className="ml-3"
        >
          Register Offline
        </Button>
        <Button
          onClick={() => {
            setRegisterThrough("PAYPAL")
            validateOtherFields()
            formik.handleSubmit()
          }}
        >
          Register With Paypal
        </Button>
      </div>
    </section>
  )
}

const GetCost = ({ costs = {}, name }) => {
  return <>(${costs[name]})</>
}

export default Tennis

const EventConstants = {
  tennisCosts: [
    {
      label: "Adult Singles Cost",
      name: "adult-singles",
      cost: 0,
      touched: false,
      error: false,
    },
    {
      label: "Adult Doubles Cost",
      name: "adult-doubles",
      cost: 0,
      touched: false,
      error: false,
    },
    {
      label: "Youth Singles Cost",
      name: "youth-singles",
      cost: 0,
      touched: false,
      error: false,
    },
    {
      label: "Youth Doubles Cost",
      name: "youth-doubles",
      cost: 0,
      touched: false,
      error: false,
    },
    {
      label: "Kids Singles Cost",
      name: "kids-singles",
      cost: 0,
      touched: false,
      error: false,
    },
  ],
}

const skillLevels = [
  { name: 'Beginner', value: 1 }, 
  { name: 'Medium', value: 2 }, 
  { name: 'Advanced', value: 3 }
]

export { EventConstants, skillLevels }
